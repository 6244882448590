.otp-field {
  display: flex;
  justify-content: center;

  span {
    color: #000 !important;
  }

  input {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    background: #FFF !important;
    outline: none !important;
    box-shadow: none !important;
    color: #000 !important;
    text-align: center;
    margin: 0px 2px;
    font-size: 25px;
    line-height: 31px;
    -webkit-text-fill-color: #000;
    -webkit-opacity: 1;
    padding: 0 !important;

    &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type=number] {
      -moz-appearance: textfield;
    }
  }
}

.otp-simple-field{
  text-align: center;
  -webkit-text-fill-color: #000;
  -webkit-opacity: 1;
  padding: 0 !important;
  border-radius: 10px;
  background: #FFF !important;
  outline: none !important;
  box-shadow: none !important;
  color: #000 !important;
  font-size: 25px;
  line-height: 40px;
  width: 150px;
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}