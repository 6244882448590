@import "fonts.scss";

#root, .App {
    text-align: center;
    min-height: 100%;
    height: 100%;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.pageLoader {
    width: 48px;
    height: 48px;
    display: inline-block;
    position: relative;
}
.pageLoader::after,
.pageLoader::before {
    content: '';
    width: 48px;
    height: 48px;
    border: 2px solid #3e5ffa;
    position: absolute;
    left: 0;
    top: 0;
    box-sizing: border-box;
    animation: rotation 2s ease-in-out infinite;
}
.pageLoader::after {
    border-color: #465bbd;
    animation-delay: 1s;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}



