.text-primary{
      color: #00ff00 !important;

	&.mad{
		color: #FF3131 !important;
	}
}
.modal-content{
	color: #000;
	background-color: #FFF;
	border: 1px solid #3F4040;
	border-radius: 30px;
	overflow: hidden;
}
th, td{
	color: #ffffff;
}
.modal-dialog{
	@media (min-width: 576px) {
		max-width: 600px !important;
	}
	.modal-title{
		margin: 0 auto;
	}
}

.wide{
	.modal-dialog{
		max-width: 900px !important;
	}
	.modal-title{
		margin: unset;
		margin-left: 1rem;
	}
}

.modal.small{
	.modal-dialog{
		max-width: 480px !important;
	}
}

.modal-content{
	padding-right: 1.5rem !important;
	padding-left: 1.5rem !important;
	@media (max-width: 768px) {
		padding-right: 0.3rem !important;
		padding-left: 0.3rem !important;
	}
}
.modal-header{
	border-bottom: none;
	.btn-close{
		background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
		transform: scale(0.5);

	}
}

.modal-backdrop{
	--bs-backdrop-opacity: 0.8 !important;
}

.modal-title{
	@media(max-width: 768px){
		font-size: calc(1rem + 0.3vw) !important;
	}
}

