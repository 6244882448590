.checkout-one{
  #mainBox{
    width: 100%;
  }
  header{
    width: 95%;
    max-width: 480px;
    height: auto;
    box-shadow: 8px 5px 20px 8px rgba(102, 102, 102, 0.25);
    margin: 15px auto;
    border-radius: 0.3rem;

    display: flex;
    align-items: center;

    .logo-container{
      flex: 10% 0 0;

      #Logo{
        width: 100px !important;
      }
    }


    .headerRight{
      font-family: Inter;
      font-size: 11px;
      font-weight: 600;
      line-height: 15px;
      letter-spacing: 0.03em;
      text-align: left;
      flex: 0 0 70%;
      padding: 0.6rem
    }
  }

  .checkoutHeader{
    h1{
      font-family: Inter;
      font-size: 24px;
      font-weight: 700;
      line-height: 29px;
      letter-spacing: 0em;
      text-align: center;
    }
    p{
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: center;

    }

  }

  .steps{

    width: 80%;
    max-width: 400px;
    position: relative;
    margin: 0 auto;
    .oneStep{
      display: flex;
      align-items: center;
      justify-content: start;
      opacity: 0.5;

      &.active{
        opacity: 1;
      }

      .stepNumber{
        width: 30px;
        height: 30px;
        line-height: 30px;
        border-radius: 50%;
        font-family: Inter;
        font-size: 14px;
        font-weight: 600;
        line-height: 30px;
        letter-spacing: 0em;
      }
      .stepTitle{
        font-family: Inter;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;

      }
    }
  }

  .flow-container{
    width: 95%;
    max-width: 480px;
    background: #FFF;
    box-shadow: 8px 5px 20px 8px rgba(102, 102, 102, 0.25);
    border: solid 1px #D2D2D2;
    padding: 1rem;
    border-radius: 0.3rem;


    .title{
      display: none;
    }

    #phone{
      width: 100% !important;
    }

    .phoneInput{
      width: 100%;
    }


    #Button{
      width: 95%;
    }
  }

  footer, #bottomText{
    font-family: Inter;
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: justify;
    color: #666666 !important;
    padding: .4rem 1rem;
  }
}
