.download-generic-two{
  #mainBox{
    width: 100%;
  }
  header{
    width: 100%;
    height: auto;
  }

  h1{
    &.title{
      font-size: 29px;
    }
  }

  .subTitle{
    background-color: #FFD600;
    padding: 10px;
    font-size: 20px;
    border-radius: 10px;
    position: relative;
    margin-bottom: 25px;
    -webkit-animation: alternate bounce 0.5s infinite;
    -moz-animation: alternate bounce 0.5s infinite;
    -o-animation: alternate bounce 0.5s infinite;
    max-width: 480px;
    margin: 25px auto;

    &:after{
      content: "";
      position: absolute;
      left: 50%;
      top: 98%;
      width: 0;
      height: 0;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-top: 20px solid #fed602;
      clear: both;
      z-index: 1;
      translate: -50%;
    }

    &.nomove{
      -webkit-animation: none;
      -moz-animation: none;
      -o-animation: none;

      &:after{
        border-left: 0px !important;
        border-right:  0px !important;
        border-top:  0px !important;
      }
    }

    @keyframes bounce {
      from {
        transform: translate3d(0, 0, 0);
      }
      to {
        transform: translate3d(0, 5px, 0);
      }
    }


    @-webkit-keyframes bounce {
      from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
      }
      to {
        -webkit-transform: translate3d(0, 5px, 0);
        transform: translate3d(0, 5px, 0);
      }
    }
  }
  .phoneInput{
    width: 230px;
  }
  $green: #04d910;
  $white: #fff;

  // Misc
  $curve: cubic-bezier(0.650, 0.000, 0.450, 1.000);
  .checkmark {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    right: 65px;
    top: 10px;
    display: block;
    stroke-width: 5;
    stroke: $white;
    stroke-miterlimit: 20;
    box-shadow: inset 0px 0px 0px $green;
    animation: fill .2s ease-in-out .1s forwards, scale .1s ease-in-out .2s both;
  }
  .checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 5;
    stroke-miterlimit: 20;
    stroke: $green;
    fill: none;
    animation: stroke .3s $curve forwards;
  }

  .checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke .3s $curve .4s forwards;
  }

  @keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }

  @keyframes scale {
    0%, 100% {
      transform: none;
    }
    50% {
      transform: scale3d(1.1, 1.1, 1);
    }
  }

  @keyframes fill {
    100% {
      box-shadow: inset 0px 0px 0px 30px $green;
    }
  }
}
